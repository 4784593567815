@use "sass:math"; @import "styles/_sass-variables.scss";@import "styles/_mixins.scss";
.heroCarousel {
  position: relative;
}

.controls {
  position: absolute;
  z-index: 14;
  left: 50%;
  transform: translate(-50%, 0);

  width: 315px;
  border-radius: var(--border-radius);
  padding: 8px var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: z-index 0.1s;

  top: calc((var(--header-min-height) / 2) + var(--ticker-height, 40px));

  --button-color: var(--color-primary);

  h6 {
    white-space: nowrap;

    @media (max-width: $mobile-max-breakpoint) {
      font-size: rem-calc(16);
    }
  }

  @media (max-width: $tablet-max-breakpoint) {
    width: 220px;
    z-index: 13;
  }

  @media (max-width: $mobile-max-breakpoint) {
    top: calc(56px + var(--ticker-height, 40px));
  }

  @media (min-width: $tablet-min-breakpoint) {
    z-index: 16;
    --header-min-height: 50px; // Get the header height
  }

  @media (min-width: $desktop-min-breakpoint) {
    --header-min-height: 54px; // Get the header height
  }
}

.controls:hover {
  background-color: var(--white);
  --color-primary: var(--black);
  button {
    color: var(--black);
  }
}

.hideControls {
  display: none;
}

.carouselScroller {
  position: relative;
  /* snap mandatory on horizontal axis  */
  scroll-snap-type: x mandatory;

  overflow-x: scroll;
  overflow-y: hidden;

  display: flex;
  align-items: stretch;

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.carouselScroller::-webkit-scrollbar {
  display: none;
}

.slide {
  width: 100%;
  flex-shrink: 0;

  scroll-snap-align: start;
}

.openMenuHide {
  @media (min-width: $tablet-min-breakpoint) and (max-width: 1100px) {
    z-index: 13;
  }

  @media (min-width: 990px) and (max-width: 1024px) {
    z-index: 16;
  }
}
